@font-face {
  font-family: 'Press';
  src: local('Press'), url(./Press_Start_2P/PressStart2P-Regular.ttf) format('truetype');
}

.prompt1 {
  font-family: Press;
}


Link:hover {
  background: red;
  color: green;
  font-family: Emilio;
  font-style: normal;
  font-weight: bolder;
}