@font-face {
  font-family: 'Press';
  src: local('Press'), url(./Press_Start_2P/PressStart2P-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Regular_Oblique.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: oblique;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Medium_Italic.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Bold_Oblique.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: oblique;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Heavy.ttf') format('truetype');
  font-weight: 800; /* Heavy */
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Heavy_Italic.ttf') format('truetype');
  font-weight: 800; /* Heavy */
  font-style: italic;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Black.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: 'Eurostile';
  src: url('./Eurostile/Eurostile_Black_Italic.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: italic;
}


.mainPageDiv {
  z-index: auto;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.videoTag {
  z-index: -1;
}
